import { Navbar } from "@mantine/core";
import { Box } from "@mantine/core";
import { NavLink as RouterNavLink, Outlet } from "react-router-dom";
import { Wallet, MessageCircle, LogOut, GraduationCap } from "lucide-react";
import { useContext } from "react";
import { stateContext } from "../context/FunctionContext";
import { useNavigate } from "react-router-dom";
//

const NavbarComponent = ({ setOpenNavbar, openNavbar }) => {
  const navigate = useNavigate();
  const { setToken } = useContext(stateContext);

  const logOut = () => {
    if (window.confirm("هل انت متأكد من تسجيل الخروج؟")) {
      localStorage.clear();
      setToken(null);
      navigate("/login");
    } else {
      return null;
    }
  };

  return (
    <Navbar hidden={!openNavbar} className="navbar" width={{ base: 200 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <RouterNavLink
          className="link"
          onClick={() => setOpenNavbar(false)}
          to="/"
        >
          <GraduationCap size={20} />
          الطلاب
        </RouterNavLink>
        <RouterNavLink
          className="link"
          onClick={() => setOpenNavbar(false)}
          to="/messenger"
        >
          <MessageCircle size={20} />
          المحادثات
        </RouterNavLink>
        <RouterNavLink
          className="link"
          onClick={() => setOpenNavbar(false)}
          to="/profile"
        >
          <Wallet size={20} />
          المحفظة
        </RouterNavLink>

        <a style={{ cursor: "pointer" }} className="link" onClick={logOut}>
          <LogOut size={20} />
          تسجيل الخروج
        </a>
        <Outlet />
      </Box>
    </Navbar>
  );
};

export default NavbarComponent;
