import {
  Button,
  FileInput,
  Modal,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useAxiosInstance } from "../axios/useAxios";
// import IdSuperVisor from "../components/modals/IdSuperVisor";
import { stateContext } from "../context/FunctionContext";

const Profile = () => {
  const { token, URL_API, setToken, setIsLoading } = useContext(stateContext);

  // const { profile, URL_API, setProfile } = useContext(stateContext);
  const [profile, setProfile] = useState();
  const [file, setFile] = useState(null);

  const axios = useAxiosInstance();

  const walletTypes = [
    { label: "ZAIN", value: "ZAIN" },
    { label: "ASIA", value: "ASIA" },
    { label: "FAST_PAY", value: "FAST_PAY" },
    { label: "NAS", value: "NAS" },
  ];
  const [chooseWallet, setChooseWallet] = useState("");
  //
  const [walletNumber, setWalletNumber] = useState("");

  // //
  const cardTypes = [
    { label: "هوية الاحوال المدنية", value: "OLD_CARD" },
    { label: "البطاقة الوطنية", value: "NATIONAL_CARD" },
  ];
  const [chooseCard, setChooseCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [frontCard, setFronCard] = useState("");
  const [backCard, setBackCard] = useState("");

  // // old card
  const [oldCardNumber, setOldCardNumber] = useState("");
  const [sheva, setSheva] = useState("");
  const [cjel, setCjel] = useState("");

  // // idCard
  const [openCardId, setOpenCardId] = useState(null);
  const uploadFile = async (e) => {
    const formData = new FormData();
    formData.append("photo", file);
    try {
      const res = await axios.put("incubator/photo", formData);

      if (res.data === "LIMIT_FILE_SIZE") {
        setFile(null);
        return alert("حجم الصورة كبير، يرجى اختيار صورة بحجم اقل");
      }

      if (res.status !== 200) {
        return alert("خطأ ما، جميع الحقول مطلوبة ");
      }
    } catch (error) {
      console.log(error.message);
    }
    localStorage.removeItem("profile");

    try {
      alert("تم تحديث الصورة بنجاح");
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleClick = async () => {
    if (walletNumber.length !== 11) {
      return alert("رقم المحفظة خطأ يرجى ادخال رقم مكون من 11");
    }

    if (chooseCard === "OLD_CARD") {
      if (
        sheva.length > 6 ||
        cjel.length > 6 ||
        cjel.length === 0 ||
        sheva.length === 0
      ) {
        return alert("يجب ادخال اقل من ٦ ارقام للصحيفة او السجل");
      }
      if (oldCardNumber.length !== 8) {
        return alert("رقم الهوية يجب ان يتكون من ٨ ارقام ");
      }
    }
    if (chooseCard === "NATIONAL_CARD") {
      if (cardNumber.length !== 12) {
        return alert("يجب ادخال ١٢ رقم للبطاقة الوطنية");
      }
    }

    const formData = new FormData();
    frontCard && formData.append("files", frontCard);
    backCard && formData.append("files", backCard);
    //
    formData.append("company", chooseWallet);
    formData.append("walletId", walletNumber);
    //
    chooseCard && formData.append("idCardType", chooseCard);
    chooseCard &&
      formData.append(
        "idCardNumber",
        chooseCard === "NATIONAL_CARD"
          ? cardNumber
          : `${oldCardNumber}-${cjel}-${sheva}`
      );

    try {
      const res = !profile?.electronicWalletOfIncubator?.idCardType
        ? await axios.post("electronic-wallet", formData)
        : await axios.patch("electronic-wallet", formData);

      if (res.status !== 200) {
        return alert("خطأ ما");
      }
      localStorage.removeItem("profile");
    } catch (error) {
      console.log(error.message);
    }

    try {
      alert("تم تحديث المعلومات بنجاح");
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const getProfile = async () => {
      const resProfile = await axios.get("/incubator/profile");
      localStorage.removeItem("profile");
      localStorage.setItem("profile", JSON.stringify(resProfile.data));
      setWalletNumber(resProfile.data.electronicWalletOfIncubator?.walletId);
      setChooseWallet(resProfile.data.electronicWalletOfIncubator?.company);
      setChooseCard(resProfile.data.electronicWalletOfIncubator?.idCardType);
      if (
        resProfile.data.electronicWalletOfIncubator?.idCardType === "OLD_CARD"
      ) {
        const [oldCardNumber, cjel, sheva] =
          resProfile.data.electronicWalletOfIncubator?.idCardNumber.split("-");
        setOldCardNumber(oldCardNumber);
        setCjel(cjel);
        setSheva(sheva);
      }
      if (
        resProfile.data.electronicWalletOfIncubator?.idCardType ===
        "NATIONAL_CARD"
      ) {
        setCardNumber(
          resProfile.data.electronicWalletOfIncubator?.idCardNumber
        );
      }

      setProfile(resProfile.data);

      console.log("profile data", resProfile.data);
    };
    getProfile();
  }, []);

  //
  return (
    <div style={{ marginTop: "2rem", height: "110vh" }}>
      {profile && (
        <>
          <h2 style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
            المعلومات الشخصيه
          </h2>
          <h2>
            الاسم : {profile?.firstName} {profile?.fatherName}{" "}
            {profile?.grandFatherName}
          </h2>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              borderBottom: "2px solid #ddd",
              paddingBottom: "1rem",
            }}
          >
            {profile.photo ? (
              <img
                src={URL_API + "image/" + profile.photo}
                alt="profile"
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "50%",
                  marginBottom: "1rem",
                }}
              />
            ) : (
              <h2 className="mb-3 text-3xl font-bold">لا يوجد صورة شخصية</h2>
            )}
            {file === null ? (
              <Button size={"lg"} color={"teal"}>
                <label id="upload">
                  رفق صورة
                  <input
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ display: "none" }}
                    type="file"
                    id="upload"
                  />
                </label>
              </Button>
            ) : (
              <Button
                size={"lg"}
                onClick={uploadFile}
                type="submit"
                color={"red"}
              >
                رفع الصورة
              </Button>
            )}
          </div>
          <h2
            style={{
              marginTop: "1.2rem",
              fontSize: "1.3rem",
              fontWeight: "bold",
            }}
          >
            معلومات الهوية والمحفظة{" "}
          </h2>
          <SimpleGrid mt="md" cols={2}>
            <Select
              size="md"
              data={walletTypes}
              onChange={(e) => {
                setChooseWallet(e);
              }}
              label="اختر المحفظة"
              placeholder="اختر"
              withAsterisk
              defaultValue={chooseWallet}
            />

            <TextInput
              withAsterisk
              size="md"
              label="رقم المحفظة "
              placeholder="يجب ان يكون رقم هاتف ١١ رقم"
              onChange={(e) => setWalletNumber(e.target.value)}
              type={"number"}
              defaultValue={walletNumber}
            />
          </SimpleGrid>
          {
            <SimpleGrid mt={"xl"} cols={2}>
              <Select
                size="md"
                data={cardTypes}
                onChange={(e) => {
                  setChooseCard(e);
                }}
                label="اختر الهوية"
                placeholder="اختر"
                value={chooseCard}
                withAsterisk
              />
              {chooseCard === "OLD_CARD" ? (
                <SimpleGrid cols={3}>
                  <TextInput
                    withAsterisk
                    size="md"
                    label="رقم الصحيفة"
                    placeholder="الحد الاقصى 6 ارقام"
                    onChange={(e) => setSheva(e.target.value)}
                    type={"number"}
                    value={sheva}
                    required
                  />
                  <TextInput
                    withAsterisk
                    size="md"
                    label="رقم السجل"
                    placeholder="الحد الاقصى 6 ارقام"
                    onChange={(e) => setCjel(e.target.value)}
                    type={"number"}
                    value={cjel}
                    required
                  />
                  <TextInput
                    withAsterisk
                    size="md"
                    placeholder="يجب ادخال 8 ارقام"
                    label="رقم الهوية"
                    onChange={(e) => setOldCardNumber(e.target.value)}
                    type={"number"}
                    value={oldCardNumber}
                    required
                  />
                </SimpleGrid>
              ) : (
                <TextInput
                  withAsterisk
                  size="md"
                  placeholder="يجب ادخال 12 رقم"
                  label="البطاقة الوطنية"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  type={"number"}
                />
              )}
            </SimpleGrid>
          }
          {/* {openCardId && (
        <IdSuperVisor
          openCardId={openCardId}
          setOpenCardId={setOpenCardId}
          profile={openCardId}
        />
      )} */}
          {
            <SimpleGrid mt={"xl"} cols={2}>
              <FileInput
                withAsterisk
                size="md"
                type={"file"}
                label="الصورة الامامية للهوية"
                placeholder="اختر الصوره الامامية للهوية"
                onChange={setFronCard}
              />
              <FileInput
                withAsterisk
                size="md"
                type={"file"}
                label="الصورة الخلفية للهوية"
                placeholder="اختر الصوره الخلفية للهوية"
                onChange={setBackCard}
              />
            </SimpleGrid>
          }
          <Button
            color={"teal"}
            mt={25}
            size={"md"}
            fullWidth
            onClick={() => handleClick()}
          >
            حفظ المعلومات
          </Button>
          {/* <Button
        onClick={() => setOpenCardId(profile)}
        mt="md"
        fullWidth
        color={"teal"}
      >
        عرض الهوية الشخصيه
      </Button>{" "} */}
        </>
      )}
    </div>
  );
};

export default Profile;
